// extracted by mini-css-extract-plugin
export var gradientText = "hero-module--gradientText--xJrae";
export var greeting = "hero-module--greeting--p6TYB";
export var greeting__wrapper = "hero-module--greeting__wrapper--svsEX";
export var headerContainer = "hero-module--headerContainer--BwYed";
export var headerImg = "hero-module--headerImg--z3OA9";
export var headerInfo = "hero-module--headerInfo--w8NHR";
export var headerIntro = "hero-module--headerIntro--I3mwN";
export var headerIntroDarkMode = "hero-module--headerIntroDarkMode--+8Wqm";
export var headerIntro__wrapper = "hero-module--headerIntro__wrapper--lKSx8";
export var headerText = "hero-module--headerText--1eUqf";
export var headerWrapper = "hero-module--headerWrapper--oLYhE";
export var rainbowTextHovering = "hero-module--rainbow-text-hovering--OzNqi";
export var rainbowTextPostHovering = "hero-module--rainbow-text-post-hovering--NueVg";