import React from "react";
import { Link } from "gatsby";
import SocialLinks from "../constants/socialLinks";

import cx from "classnames";
import * as styles from "./hero.module.css";

const Hero = () => {
  return (
    <header className={styles.headerWrapper}>
      <div className={styles.headerContainer}>
        <div className={styles.headerText}>
          <div className={styles.greeting__wrapper}>
            <h1 className={cx(styles.greeting, styles.gradientText)}>
              Hi there! I'm Belle.
            </h1>
          </div>
        </div>
        <article>
          <div>
            <p className={styles.headerInfo}>
              Front-end web developer | JAMstack
            </p>
            <Link to="/contact" className="sbtn liquid-btn blue-btn btn">
              contact me
            </Link>
            <SocialLinks />
          </div>
        </article>
      </div>
    </header>
  );
};

export default Hero;
