import * as React from "react";
import * as styles from "../components/skills.module.css";
import {
  FaHtml5,
  FaCss3,
  FaJs,
  FaSass,
  FaReact,
  FaGit,
  FaGithub,
  FaNode,
} from "react-icons/fa";

import { ImAccessibility } from "react-icons/im";
import { DiResponsive } from "react-icons/di";
import {
  SiNetlify,
  SiMongodb,
  SiGatsby,
  SiGraphql,
  // SiStyledComponents,
} from "react-icons/si";

const data = [
  {
    id: 1,
    tech: <FaHtml5 />,
    name: "HTML5",
  },
  {
    id: 2,
    tech: <FaCss3 />,
    name: "CSS3",
  },
  {
    id: 3,
    tech: <FaJs />,
    name: "JavaScript",
  },
  {
    id: 4,
    tech: <FaSass />,
    name: "Sass",
  },
  {
    id: 5,
    tech: <FaReact />,
    name: "React",
  },
  {
    id: 6,
    tech: <FaNode />,
    name: "Node",
  },
  {
    id: 7,
    tech: <SiGatsby />,
    name: "Gatsby",
  },
  // {
  //   id: 8,
  //   tech: <SiStyledComponents />,
  //   name: "Styled Components",
  // },
  {
    id: 8,
    tech: <SiGraphql />,
    name: "Graphql",
  },
  {
    id: 9,
    tech: <FaGit />,
    name: "Git",
  },
  {
    id: 10,
    tech: <FaGithub />,
    name: "GitHub",
  },
  {
    id: 11,
    tech: <SiNetlify />,
    name: "Netlify",
  },
  {
    id: 12,
    tech: <SiMongodb />,
    name: "Mongodb",
  },
  {
    id: 13,
    tech: <ImAccessibility />,
    name: "Accessibility",
  },
  {
    id: 14,
    tech: <DiResponsive />,
    name: "Responsive Design",
  },
];

// Tech Component

const techs = data.map(item => {
  return (
    <div key={item.id} className={styles.skill}>
      <p>{item.tech}</p>
      <p>{item.name}</p>
    </div>
  );
});

// Techs Component

const Techonologies = () => {
  return <div className={styles.skillsWrapper}>{techs}</div>;
};

export default Techonologies;
