import React from "react";
import Title from "./Title";
import Technologies from "../constants/technologies";

import * as styles from "./skills.module.css";

const Skills = ({ titleText }) => {
  return (
    <>
      <article className={styles.toolBoxSection} aria-label="region skill">
        <div className={styles.toolBoxWrapper}>
          <Title titleText={titleText} />

          <p>The skills and technologies I regularly use to create projects</p>
          <Technologies />
          <div className={styles.selfLearning}>
            <h3>Self-learning: </h3>
            <p>
              I'm currently participating in #100DaysOfCode to improve my skills
              and knowledge in various technologies related to web development.
              I also have a strong interest in CSS and enjoy creating pure CSS
              images.{" "}
              <a
                href="https://twitter.com/BCarrie5"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Check out my journey here.</span>
              </a>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Skills;
