import React from "react";
import { graphql } from "gatsby";
import Hero from "../components/Hero";
import Skills from "../components/Skills";
import Layout from "../components/Layout";
import Projects from "../components/Projects";
// import Blogs from "../components/Blogs";
import Seo from "../components/Seo";

export default function Home({ data }) {
  const { allDatoCmsProjectPage } = data;

  const projects = allDatoCmsProjectPage.edges[0].node.projects;

  return (
    <Layout>
      <Seo title="Home" />
      <Hero />
      <Skills titleText="skills and toolbox" />
      <Projects projects={projects} titleText="featured projects" showLink />
      {/* <Blogs blogs={blogs} titleText="latest articles" showLink />  */}
    </Layout>
  );
}

export const query = graphql`
  {
    allDatoCmsProjectPage {
      edges {
        node {
          title
          slug
          projects {
            title
            id
            stack {
              stack
            }
            siteUrl
            githubUrl
            desc
            featured
            image {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
